import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IErrorsConfiguration, NgxErrorsModule } from '@ngspot/ngx-errors';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxCurrencyConfig, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { INgxLoadingConfig, NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from 'ngx-webstorage';
import { NgxWebstorageConfiguration } from 'ngx-webstorage/lib/config';

const CURRENCY_CONFIG: NgxCurrencyConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  thousands: '.',
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  nullable: false,
  inputMode: NgxCurrencyInputMode.Financial,
};

const MASK_CONFIG: Partial<IConfig> = {
  dropSpecialCharacters: false,
};

const ERRORS_CONFIG: IErrorsConfiguration = {
  showErrorsWhenInput: 'touchedAndDirty',
};

const LOADING_CONFIG: INgxLoadingConfig = {
  animationType: ngxLoadingAnimationTypes.threeBounce,
  fullScreenBackdrop: false,
  backdropBackgroundColour: 'rgba(255,255,255,0.5)',
};

const STORAGE_CONFIG: NgxWebstorageConfiguration = {
  prefix: 'PAYCLASS-STORAGE',
  separator: '.',
  caseSensitive: false,
};

@NgModule({
  imports: [
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgxErrorsModule.configure(ERRORS_CONFIG),
    NgxLoadingModule.forRoot(LOADING_CONFIG),
    NgxMaskDirective,
    NgxMaskPipe,
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  providers: [
    provideNgxMask(MASK_CONFIG),
    provideEnvironmentNgxCurrency(CURRENCY_CONFIG),
    provideNgxWebstorage(withNgxWebstorageConfig(STORAGE_CONFIG), withLocalStorage(), withSessionStorage()),
  ],
})
export class CoreSharedModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreSharedModule) {
    if (parentModule) {
      throw new Error('CoreSharedModule has already been loaded. Import modules in the AppModule only.');
    }
  }
}
